import React from "react"
import styled from "styled-components"
import { space, typography, color } from "styled-system"
import Text from "./text"

const Container = styled.li`
  ${space}
  ${typography}
  ${color}
  position: relative;
  /* display: block; */
  list-style-type: decimal-leading-zero;
  list-style-position: outside;
`
Container.defaultProps = {
  color: "black.0",
  fontFamily: "sansSerif",
}

export default props => (
  <Container mb={["y.mobile.0.5", "y.desktop.0.5"]}>
    <Text
      fontFamily="sansSerif"
      fontSize={["mobile.-1", "desktop.-1"]}
      fontWeight={4}
      color="text"
      {...props}
    >
      {props.children}
    </Text>
  </Container>
)
