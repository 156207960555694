import styled from "styled-components"
import { compose, space, layout, color } from "styled-system"
import { motion } from "framer-motion"

export const Container = styled(motion.div)`
  ${compose(layout, space, color)}

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
`
Container.defaultProps = {
  bg: "black.0",
  display: ["none", "block"],
  height: ["heights.mobile.3.0", "heights.desktop.4.0"],
}

export const Spacer = styled.div`
  ${layout}
`
Spacer.defaultProps = {
  display: ["none", "block"],
  height: "heights.desktop.4.0",
}
