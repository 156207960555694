import styled from "styled-components"
import Text from "./text"

const XS = styled(Text)``

XS.defaultProps = {
  as: "span",
  fontFamily: "sansSerif",
  fontSize: ["mobile.-2", "desktop.-2"],
  lineHeight: ["mobile.0.5", "desktop.0.5"],
}

export default XS
