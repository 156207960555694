import React from "react"
import { Container, Inner, Bar } from "./burger.styles"

export default ({ onClick, value }) => {
  return (
    <Container onClick={onClick}>
      <Inner>
        <Bar active={value} />
        <Bar active={value} />
      </Inner>
    </Container>
  )
}
