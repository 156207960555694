import styled from "styled-components"
import { layout, space, color } from "styled-system"
import Text from "./text"
import BackgroundImg from "../images/bg-contact.jpg"

export const Container = styled.div`
  ${layout}

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  visibility: hidden;
`
Container.defaultProps = {}

export const Plane = styled.div`
  ${color}

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  transform: translate3d(0, -100%, 0);
`
Plane.defaultProps = {
  bg: "black.0",
}

export const Inner = styled.div`
  ${space}

  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  overflow-y: scroll;
`

Inner.defaultProps = {}

export const Footer = styled.div`
  ${layout}
  ${space}

  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`
Footer.defaultProps = {
  px: "x.2",
  height: "heights.mobile.3.0",
}

export const TextSpan = styled(Text)`
  width: 3.8rem;
  height: 3.8rem;
  color: ${(props) => (props.active ? "black" : "white")};
  text-align: center;
  background: ${(props) => (props.active ? "white" : "transparent")};
  border-radius: 0.4rem;
  cursor: pointer;
  transition: all 250ms;
`
TextSpan.defaultProps = {
  fontSize: "3.6rem",
  color: "white",
}
