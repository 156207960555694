import React, { useState, useEffect, Fragment, useContext } from "react"
import { useLocation } from "@reach/router"
import { Container, Inner, Spacer } from "./mobile-header.styles"
import ApplicationContext from "../utils/application-context"

import Burger from "./burger"
import Logo from "./logo"
import MediaNav from "./media-nav"
import MobileNav from "./mobile-nav"

export default () => {
  const [applicationState, setApplicationState] = useContext(ApplicationContext)
  const location = useLocation()
  const [mobileNavActive, setMobileNavActive] = useState(false)
  const [mediaNav, setMediaNav] = useState(false)

  const toggleMediaNav = () => {
    setMediaNav(!mediaNav)
    setMobileNavActive(true)
  }

  const handleToggleNav = () => {
    setMobileNavActive(!mobileNavActive)
  }

  const handleReset = () => {
    setMediaNav(false)
  }

  useEffect(() => {
    setMobileNavActive(false)
  }, [location])

  useEffect(() => {
    setApplicationState((state) => ({
      ...state,
      preventTransition: mobileNavActive,
    }))
  }, [mobileNavActive])

  return (
    <Fragment>
      <Container>
        <Inner navActive={mobileNavActive}>
          <Burger onClick={handleToggleNav} value={mobileNavActive} />
          <Logo />
          <MediaNav onClick={toggleMediaNav} value={mediaNav} />
        </Inner>
      </Container>
      <MobileNav
        active={mobileNavActive}
        mediaNav={mediaNav}
        onReset={handleReset}
        onClose={() => setMobileNavActive(false)}
      />
      <Spacer />
    </Fragment>
  )
}
