import React, { createContext, useState } from "react"

const ApplicationContext = createContext()

const initialState = { preventTransition: false }

export const ApplicationProvider = ({ children }) => {
  const [context, setContext] = useState(initialState)

  return (
    <ApplicationContext.Provider value={[context, setContext]}>
      {children}
    </ApplicationContext.Provider>
  )
}

export default ApplicationContext
