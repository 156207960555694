import styled from "styled-components"
import Text from "./text"

const H1 = styled(Text)``

H1.defaultProps = {
  as: "h1",
  fontFamily: "sansSerif",
  fontSize: ["mobile.2", "desktop.5"],
  fontWeight: 4,
  lineHeight: ["mobile.4.0", "desktop.4.0"],
  my: 0,
}

export default H1
