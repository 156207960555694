import styled from "styled-components"
import { variant } from "styled-system"
import Text from "./text"

const Button = styled(Text)(
  variant({
    variants: {
      default: {
        color: "text",
      },
      disabled: {
        color: "black.6",
      },
    },
  })
)

Button.defaultProps = {
  as: "span",
  variant: "default",
  fontFamily: "sansSerif",
  fontSize: ["mobile.-1", "desktop.-1"],
  fontWeight: 7,
  lineHeight: "normal",
  letterSpacing: "0.02em",
}

export default Button
