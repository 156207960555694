import React, { useEffect, useRef, useContext } from "react"
import { ThemeProvider } from "styled-components"
import { useAnimation, AnimatePresence } from "framer-motion"
import { useMediaQuery } from "react-responsive"
import theme from "../theme"
import "../theme/fonts.css"
import { GlobalStyle, Container, Main, Plane } from "./layout.styles"
import ApplicationContext from "../utils/application-context"

import Header from "./header"
import Footer from "./footer"

export default function Layout({ location, children }) {
  const [applicationState] = useContext(ApplicationContext)
  const isMobile = useMediaQuery({ maxWidth: "40em" })
  const prevLocation = useRef(location.pathname.replace(/\//g, ""))
  const controls = useAnimation()

  const pageTransition = {
    show: {
      y: "0",
      transition: {
        duration: 0.5,
      },
    },
    hide: {
      y: "-100%",
      transition: {
        duration: 0.5,
      },
    },
  }

  const updateScrollPosition = () => {
    const savedPosition = JSON.parse(
      window.sessionStorage.getItem(
        `@@scroll|${location.pathname}|${location.key}`
      )
    )
    window.scrollTo(0, savedPosition || 0)
  }

  useEffect(() => {
    if ("scrollRestoration" in history) {
      // Avoid jumping on history back button
      history.scrollRestoration = "manual"
    }
  }, [])

  useEffect(() => {
    if (applicationState.preventTransition) return

    const pathname = location.pathname.replace(/\//g, "")

    const sequence = async () => {
      await controls.start("show")
      return await controls.start("hide")
    }

    if (pathname !== prevLocation.current) sequence()
    prevLocation.current = pathname
  }, [location])

  return (
    <React.Fragment>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Container>
          <Header />
          <Main
            initial={{ opacity: 0, y: 35 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              y: { delay: 0.8, duration: 0.7, ease: "easeOut" },
              opacity: { delay: 0.8, duration: 0.35, ease: "easeInOut" },
            }}
          >
            <AnimatePresence
              initial={false}
              exitBeforeEnter
              onExitComplete={updateScrollPosition}
            >
              {children}
            </AnimatePresence>
          </Main>
          <Footer />
        </Container>
        <Plane initial={false} animate={controls} variants={pageTransition} />
      </ThemeProvider>
    </React.Fragment>
  )
}
