import styled from "styled-components"
import Text from "./text"

const H4 = styled(Text)``

H4.defaultProps = {
  as: "h4",
  fontFamily: "sansSerif",
  fontSize: ["mobile.2", "desktop.2"],
  lineHeight: ["mobile.1.5", "desktop.1.5"],
  mt: ["y.mobile.1.5", "y.desktop.1.5"],
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
}

export default H4
