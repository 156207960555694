import React from "react"
import { AnimatePresence } from "framer-motion"
import { Container, Inner, NavItem } from "./social-nav.styles"

export default ({ active }) => {
  const listAnimation = {
    visible: {
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.5,
      },
    },
    hidden: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  }

  const itemAnimation = {
    visible: {
      opacity: 1,
      transition: { duration: 0.2 },
    },
    hidden: {
      opacity: 0,
      transition: { duration: 0.2 },
    },
  }

  return (
    <AnimatePresence>
      {active && (
        <Container
          key="social-nav"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={listAnimation}
        >
          <Inner>
            <NavItem
              href="https://www.facebook.com/GauschFilm/"
              target="blank"
              variants={itemAnimation}
            >
              facebook
            </NavItem>
            <NavItem
              href="https://www.instagram.com/gauschfilm/"
              target="blank"
              variants={itemAnimation}
            >
              instagram
            </NavItem>
            <NavItem
              href="https://vimeo.com/gauschfilm/"
              target="blank"
              variants={itemAnimation}
            >
              vimeo
            </NavItem>
            <NavItem
              variants={itemAnimation}
              href="https://www.youtube.com/channel/UCWni06KeU2HMGXgmC7iWYxg"
              target="blank"
            >
              youtube
            </NavItem>
          </Inner>
        </Container>
      )}
    </AnimatePresence>
  )
}
