import styled from "styled-components"
import { layout, flexbox } from "styled-system"
import { motion } from "framer-motion"
import Text from "./text"

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

Container.defaultProps = {
  display: ["flex", "block"],
  flexDirection: "column",
  alignItems: "center",
}

export const Inner = styled.nav`
  ${layout}
  ${flexbox}
`
Inner.defaultProps = {
  display: ["flex", "block"],
  flexDirection: "column",
  alignItems: "center",
}

export const NavItem = styled(Text)`
  user-select: none;
  &.active {
    opacity: 0.6;
  }
`

NavItem.defaultProps = {
  as: motion.a,
  fontFamily: "mono",
  activeClassName: "active",
  partiallyActive: true,
  fontSize: ["mobile.1", "desktop.0"],
  mx: "x.3",
  my: ["y.mobile.0.5", 0],
  py: ["y.mobile.0.5", 0],
  variant: "dark",
  rel: "noopener",
}
