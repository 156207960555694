import styled from "styled-components"
import { space, layout, color } from "styled-system"

export const Container = styled.div`
  ${layout}
  ${space}

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
  backface-visibility: hidden;
`
Container.defaultProps = {
  display: ["block", "none"],
  height: "heights.mobile.2.5",
}

export const Inner = styled.div`
  ${layout}
  ${space}
  ${color}
  
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  background: ${props => (props.navActive ? "transparent" : "#000")};
  transition: background-color 0ms linear ${props =>
    props.navActive ? "150ms" : "350ms"};
`
Inner.defaultProps = {
  bg: ["black.0"],
  height: "heights.mobile.2.5",
  pl: ["1.6rem", "2.9rem"],
  pr: ["1.6rem", "4.3rem"],
}

export const Spacer = styled.div`
  ${layout}
`
Spacer.defaultProps = {
  display: ["block", "none"],
  height: "heights.mobile.2.5",
}
