import React from "react"
import Text from "./text"

export default props => (
  <Text
    fontFamily="sansSerif"
    as="em"
    fontSize={["mobile.0", "desktop.0"]}
    fontStyle="italic"
    color="text"
    {...props}
  >
    {props.children}
  </Text>
)
