import * as React from "react"
import smoothscroll from "smoothscroll-polyfill"
import { PreviewStoreProvider } from "gatsby-source-prismic"
import { ApplicationProvider } from "./src/utils/application-context"
import Layout from "./src/components/layout"

export const wrapRootElement = ({ element }) => {
  return (
    <PreviewStoreProvider>
      <ApplicationProvider>{element}</ApplicationProvider>
    </PreviewStoreProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const shouldUpdateScroll = () => false

export const onInitialClientRender = () => {
  smoothscroll.polyfill()
}
