import React, { useRef, useState, useEffect } from "react"
import { navigate } from "gatsby-link"
import { useLockBodyScroll } from "react-use"
import { Transition } from "react-transition-group"

import { Container, Plane, Inner, Footer, TextSpan } from "./mobile-nav.styles"
import { useAnimation, fadeIn, fadeOut } from "./mobile-nav.animations"

import MainNav from "./main-nav"
import SocialNav from "./social-nav"
import LegalNav from "./legal-nav"
import Credits from "./credits"
import Beer from "./beer"

export default ({ active, mediaNav, onClose, onReset }) => {
  useLockBodyScroll(active)

  const nav = useRef()
  const plane = useRef()

  const fadeInTl = useAnimation({ plane, nav }, fadeIn)
  const fadeOutTl = useAnimation({ plane, nav }, fadeOut)

  const show = () => {
    fadeInTl.current.play(0)
  }

  const hide = () => {
    fadeOutTl.current.play(0).eventCallback("onComplete", onReset)
  }

  const [state, setState] = useState("main")

  useEffect(() => {
    if (mediaNav) setState("media")
    if (!mediaNav && state === "media") setState("main")
  }, [mediaNav])

  const toggleState = (nextState) => {
    setState(nextState === state ? "main" : nextState)
    onReset()
  }

  return (
    <>
      <Plane ref={plane} />
      <Transition in={active} timeout={750} onEnter={show} onExit={hide}>
        <Container ref={nav}>
          <Inner>
            <MainNav active={state === "main"} onClose={onClose} />
            <SocialNav active={state === "media"} />
            <LegalNav active={state === "legal"} onClose={onClose} />
            <Credits active={state === "credits"} />
          </Inner>

          <Footer>
            <TextSpan
              lineHeight={"inherit"}
              onClick={() => navigate("/credits")}
              active={state === "credits"}
            >
              ©
            </TextSpan>
            {/* <Beer color="#fff" /> */}
            <TextSpan
              onClick={() => toggleState("legal")}
              active={state === "legal"}
            >
              §
            </TextSpan>
          </Footer>
        </Container>
      </Transition>
    </>
  )
}
