import Text from "./text"
import H0 from "./h0"
import H1 from "./h1"
import H2 from "./h2"
import H3 from "./h3"
import H4 from "./h4"
import H5 from "./h5"
import H6 from "./h6"
import P from "./p"
import A from "./a"
import Small from "./small"
import XS from "./xs"
import Button from "./button"
import Label from "./label"
import Link from "./link"
import Strong from "./strong"
import Em from "./em"
import List from "./list"
import ListItem from "./list-item"
import OListItem from "./o-list-item"
import Span from "./span"

Text.H0 = H0
Text.H1 = H1
Text.H2 = H2
Text.H3 = H3
Text.H4 = H4
Text.H5 = H5
Text.H6 = H6
Text.P = P
Text.A = A
Text.Small = Small
Text.XS = XS
Text.Button = Button
Text.Label = Label
Text.Link = Link
Text.Strong = Strong
Text.Em = Em
Text.List = List
Text.ListItem = ListItem
Text.OListItem = OListItem
Text.Span = Span

export default Text
