import styled from "styled-components"
import Text from "./text"

const P = styled(Text)``

P.defaultProps = {
  as: "p",
  fontFamily: "sansSerif",
  fontSize: ["mobile.0", "desktop.0"],
  color: "text",
  mt: 0,
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
}

export default P
