import React from "react"

import DesktopHeader from "./desktop-header"
import MobileHeader from "./mobile-header"

export default () => {
  return (
    <>
      <MobileHeader />
      <DesktopHeader />
    </>
  )
}
