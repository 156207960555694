import styled from "styled-components"
import Text from "./text"

const H2 = styled(Text)``

H2.defaultProps = {
  as: "h2",
  fontFamily: ["sansSerif", "serif"],
  fontSize: ["mobile.4", "desktop.4"],
  fontWeight: [5, 3],
  color: "text",
  lineHeight: ["mobile.3.0", "desktop.2.0"],
  mt: ["y.mobile.2.0", "y.desktop.2.0"],
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
}

export default H2
