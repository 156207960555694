import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import Text from "../components/text"

const Container = styled(motion.div)`
  display: flex;
  justify-content: center;
`

export default ({ active }) => (
  <AnimatePresence>
    {active && (
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit="initial"
      >
        <Text.P variant="dark" fontSize="mobile.-1">
          Copyright {new Date().getFullYear()}
          <br />
          Content: Laurin Gausch
          <br />
          Design and Code:{" "}
          <Text.A
            href="https://4com.at"
            target="blank"
            variant="dark"
            activeIndicator
          >
            Florian Nebenführ
          </Text.A>
        </Text.P>
      </Container>
    )}
  </AnimatePresence>
)
