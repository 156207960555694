import styled from "styled-components"
import Link from "gatsby-link"
import Text from "./text"

const InternalLink = styled(Text)`
  &:active {
    color: #000;
  }
`

InternalLink.defaultProps = {
  as: Link,
  fontFamily: "sansSerif",
  fontSize: ["mobile.-2", "desktop.-2"],
  color: "black.0",
}

export default InternalLink
