import React, { Fragment, useState, useEffect, useRef } from "react"
import { useMediaQuery } from "react-responsive"
import { useAnimation, useViewportScroll } from "framer-motion"
import { Container, Spacer } from "./desktop-header.styles"

import Logo from "./logo"
import MainNav from "./main-nav"
import MediaNav from "./media-nav"
import SocialNav from "./social-nav"

export default () => {
  const isLarge = useMediaQuery({ minWidth: "75em" })

  const [mediaNav, setMediaNav] = useState(false)
  const container = useRef()
  const headerFixed = useRef(true)

  const savedScrollPosition = useRef(0)
  const controls = useAnimation()
  const { scrollY } = useViewportScroll()

  const getScrollDirection = (currentScrollPositionY) => {
    let direction

    if (currentScrollPositionY < scrollY.prev) {
      direction = "up"
    } else if (currentScrollPositionY > scrollY.prev) {
      direction = "down"
    }

    return direction
  }

  const handleShow = async () => {
    controls.start("in")
    headerFixed.current = true
  }

  const handleHide = async () => {
    controls.start("out")
    headerFixed.current = false
  }

  const handleScroll = (scrollPositionY) => {
    const direction = getScrollDirection(scrollPositionY)

    if (direction === "up") {
      if (
        !headerFixed.current &&
        scrollPositionY < savedScrollPosition.current - 20
      )
        handleShow()
    }

    if (direction === "down") {
      if (headerFixed.current && scrollPositionY > window.innerHeight - 120)
        handleHide()
      savedScrollPosition.current = scrollPositionY
    }
  }

  useEffect(() => {
    const unsubscribe = scrollY.onChange(handleScroll)
    return () => {
      unsubscribe()
    }
  }, [])

  const headerAnimation = {
    in: {
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
    out: {
      y: "-100%",
      transition: {
        duration: 0.25,
        ease: "easeIn",
      },
    },
  }

  return (
    <Fragment>
      {isLarge && <Logo />}
      <Container
        ref={container}
        initial={false}
        animate={controls}
        variants={headerAnimation}
      >
        {!isLarge && <Logo />}
        <MainNav active={!mediaNav} />
        <SocialNav active={mediaNav} />
        <MediaNav value={mediaNav} onClick={() => setMediaNav(!mediaNav)} />
      </Container>
      <Spacer />
    </Fragment>
  )
}
