import React from "react"
import styled from "styled-components"
import { space, position } from "styled-system"
import Text from "./text"

const Container = styled.li`
  ${space}
  position: relative;
  display: block;
`

const Icon = styled.svg`
  ${position}
  position: absolute;
  left: 0;
  height: 1.6rem;
  width: 1.6rem;
  transform: translate3d(calc(-100% - 0.6rem), -50%, 0);
`
Icon.defaultProps = {
  top: ["1.6em", "1.3rem"],
}

export default props => (
  <Container mb={["y.mobile.0.5", "y.desktop.0.5"]}>
    <Icon viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect fill="#000" x="0" y="4" width="8" height="8"></rect>
      </g>
    </Icon>
    <Text
      fontFamily="sansSerif"
      fontSize={["mobile.-1", "desktop.-1"]}
      fontWeight={4}
      color="text"
      {...props}
    >
      {props.children}
    </Text>
  </Container>
)
