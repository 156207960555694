import styled from "styled-components"
import Text from "./text"

const Strong = styled(Text)``

Strong.defaultProps = {
  as: "strong",
  fontFamily: "sansSerif",
  fontSize: ["mobile.0", "desktop.0"],
  fontWeight: 7,
  color: "text",
}

export default Strong
