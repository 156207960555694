import styled from "styled-components"
import { compose, space, layout, position } from "styled-system"

export const Container = styled.div`
  top: 2.7rem;
  right: 2.9rem;
  line-height: 0;
  background: ${(props) => (props.active ? "white" : "transparent")};
  border-radius: 0.4rem;
  cursor: pointer;
  transition: background-color 250ms;
  ${compose(space, position)}

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`
Container.defaultProps = {
  position: ["static", "absolute"],
  p: ["1.3rem"],
  mr: "-1.3rem",
}

export const Img = styled.img`
  user-select: none;
  mix-blend-mode: difference;
  ${layout}
`
Img.defaultProps = {
  width: ["2.2em", "3.2rem"],
  height: ["2.2rem", "3.2rem"],
}
