import styled from "styled-components"
import Text from "./text"

const H5 = styled(Text)``

H5.defaultProps = {
  as: "h5",
  fontFamily: "sansSerif",
  fontSize: ["mobile.1", "desktop.1"],
  fontWeight: 5,
  mt: ["y.mobile.1.5", "y.desktop.1.5"],
  mb: ["y.mobile.0.5", "y.desktop.0.5"],
}

export default H5
