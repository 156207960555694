import React from "react"
import styled from "styled-components"
import Text from "./text"

const Anchor = styled(Text)`
  position: relative;
  display: inline-block;
  z-index: 0;
  color: ${props => props.theme.colors.white["0"]};
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  transition: color 150ms ease-in-out 20ms;

  &:hover {
    color: ${props => props.theme.colors.black["2"]};
  }

  &:active {
    color: ${props => props.theme.colors.black["0"]};
  }

  &::after {
    position: absolute;
    top: 0.2rem;
    bottom: 0.2rem;
    left: 0;
    z-index: -1;
    display: ${props => (props.activeIndicator ? "block" : "none")};
    width: 100%;
    background: ${props => props.theme.colors.black["1"]};
    transition: width 250ms ease-in-out 20ms;
    content: "";
  }

  &:hover::after {
    right: 0;
    left: auto;
    width: 0%;
  }
`
Anchor.defaultProps = {
  as: "a",
  fontFamily: "sansSerif",
  rel: "noopener",
  // lineHeight: ["mobile.0.5", "desktop.0.5"],
}

export default props => {
  return <Anchor {...props}>{props.children}</Anchor>
}
