import React from "react"
import { AnimatePresence } from "framer-motion"
import { useMediaQuery } from "react-responsive"
import Link from "gatsby-link"
import { Container, Inner, NavItem, NavItemText } from "./main-nav.styles"

export default ({ active, onClose }) => {
  const navigation = [
    {
      key: "nav-legal-01",
      name: "terms of service",
      to: "/legal", // ?filter=terms-of-service
    },
    {
      key: "nav-legal-02",
      name: "privacy policy",
      to: "/legal",
      // to: "/legal?filter=privacy-policy",
    },
  ]

  const isMobile = useMediaQuery({ maxWidth: "40em" })

  const listAnimation = {
    visible: {
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.5,
      },
    },
    hidden: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  }

  const itemAnimation = {
    visible: {
      opacity: 1,
      transition: { duration: 0.2 },
    },
    hidden: {
      opacity: 0,
      transition: { duration: 0.2 },
    },
  }

  return (
    <AnimatePresence>
      {active && (
        <Container
          key="legal-nav"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={listAnimation}
        >
          <Inner>
            {navigation.map((item) => {
              return isMobile ? (
                <NavItem key={item.key} variants={itemAnimation}>
                  <NavItemText
                    as={Link}
                    to={item.to}
                    entry={{
                      trigger: ({}) => {
                        console.log("onClose")
                        onClose()
                      },
                    }}
                    activeClassName="active"
                  >
                    {item.name}
                  </NavItemText>
                </NavItem>
              ) : (
                <NavItem key={item.key} variants={itemAnimation}>
                  <NavItemText to={item.to} activeClassName="active">
                    {item.name}
                  </NavItemText>
                </NavItem>
              )
            })}
          </Inner>
        </Container>
      )}
    </AnimatePresence>
  )
}
