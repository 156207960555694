import React from "react"
import styled from "styled-components"
import { space, color, typography } from "styled-system"

const Unordered = styled.ul`
  ${space}
`
Unordered.defaultProps = {
  pl: ["2.5rem", 0],
}

const Ordered = styled.ol`
  ${space}
  ${color}
  ${typography}
  list-style-type: decimal;
`

Ordered.defaultProps = {
  color: "black.6",
  fontFamily: "sansSerif",
  fontSize: ["mobile.-1", "desktop.-1"],
  mt: 0,
  pl: "2.5rem",
}

export default ({ ordered, children }) => {
  if (ordered)
    return <Ordered mb={["y.mobile.1.0", "y.desktop.1.0"]}>{children}</Ordered>
  return (
    <Unordered mb={["y.mobile.1.0", "y.desktop.1.0"]}>{children}</Unordered>
  )
}
