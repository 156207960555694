import styled from "styled-components"
import Text from "./text"

const H3 = styled(Text)``

H3.defaultProps = {
  as: "h3",
  fontFamily: "sansSerif",
  fontSize: ["mobile.2", "desktop.2"],
  fontWeight: 5,
  letterSpacing: 1,
  lineHeight: ["mobile.2.5", "desktop.2.0"],
  color: "black.2",
  mt: ["y.mobile.2.0", "y.desktop.2.0"],
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
}

export default H3
