import styled from "styled-components"
import { compose, layout, position, color } from "styled-system"
import Link from "gatsby-link"

export const Container = styled(Link)`
  ${compose(position, layout, color)}

  z-index: 1002;
  line-height: 0;
  mix-blend-mode: difference;
`
Container.defaultProps = {
  position: ["static", "fixed", "fixed"],
  top: ["unset", "2.7rem"],
  left: ["unset", "2.7rem"],
}

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  ${layout}
`

Title.defaultProps = {
  width: ["heights.mobile.2.0", "heights.desktop.2.0"],
  height: ["heights.mobile.2.0", "heights.desktop.2.0"],
}

export const Svg = styled.svg`
  width: 100%;
  height: 100%;

  #arc-path {
    fill: ${(props) => props.color};
    /* stroke: #1565c0; */
    stroke-opacity: 0;
    stroke-width: 10;
    stroke-dasharray: 30, 15;
    stroke-miterlimit: 10;
    stroke-linejoin: miter;
  }
`
