import React from "react"
import "intersection-observer"
import { useInView } from "react-intersection-observer"
import BeerImg from "../images/beer.svg"
import { Container, Spacer, Inner, TextSpan, Beer } from "./footer.styles"

export default () => {
  const [spacer, inView] = useInView({ threshold: 0.6 })

  return (
    <Container>
      <Spacer ref={spacer} />
      <Inner>
        <TextSpan className={inView && "active"} to="/credits">
          ©
        </TextSpan>
        {/* <Beer
          className={inView && "active"}
          src={BeerImg}
          alt="Beer"
          width="30px"
          height="40px"
        /> */}
        <TextSpan className={inView && "active"} to="/legal">
          §
        </TextSpan>
      </Inner>
    </Container>
  )
}
