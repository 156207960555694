import styled from "styled-components"
import { compose, color, space, typography, variant } from "styled-system"

const Primitive = styled.span`
  * {
    color: inherit;
  }
`

const Text = styled(Primitive)(
  compose(typography, color, space),
  variant({
    variants: {
      left: { textAlign: "left" },
      center: { textAlign: "center" },
      right: { textAlign: "right" },
      light: { color: "text" },
      dark: {
        color: "white.0",
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
      },
    },
  })
)

Text.defaultProps = {
  fontFamily: "sansSerif",
  lineHeight: ["mobile.1.5", "desktop.1.0"],
}
export default Text
