import styled from "styled-components"
import Text from "./text"

const H6 = styled(Text)``

H6.defaultProps = {
  as: "h6",
  fontFamily: "mono",
  fontSize: ["mobile.0", "desktop.0"],
  fontWeight: 4,
  color: "black.6",
  mt: ["y.mobile.1.0", "-y.desktop.1.0"],
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
}

export default H6
