import styled, { keyframes } from "styled-components"
import { space, layout, color } from "styled-system"
import Link from "gatsby-link"
import Text from "./text"

export const Container = styled.footer`
  position: relative;
  ${color}
`
Container.defaultProps = {
  bg: "white",
}

export const Spacer = styled.div`
  ${layout}
`
Spacer.defaultProps = {
  height: ["heights.mobile.6.0", "heights.desktop.6.0"],
}

export const Inner = styled.div`
  ${layout}
  ${space}

  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`
Inner.defaultProps = {
  pl: ["x.2", "4rem"],
  pr: ["x.2", "3.1rem"],
  py: ["y.mobile.2.0", "y.desktop.2.0"],
}

export const TextSpan = styled(Text)`
  transform: scale(0.8);
  opacity: 0;
  transition: opacity 100ms ease-in-out, transform 150ms ease-in-out;

  &.active {
    transform: scale(1);
    opacity: 1;
  }
`
TextSpan.defaultProps = {
  as: Link,
  fontSize: ["mobile.2", "desktop.3"],
  lineHeight: ["mobile.2.0", "desktop.2.0"],
  color: "black.0",
}

const shake = keyframes`
  2% {
    transform: translate(0, 0) rotate(-2deg);
  }
  4% {
    transform: translate(0, 0) rotate(4.5deg);
  }
  6% {
    transform: translate(0, 0) rotate(2deg);
  }
  8% {
    transform: translate(-0, 0) rotate(2.5deg);
  }
  10% {
    transform: translate(0, 0) rotate(-2deg);
  }
  12% {
    transform: translate(0, 0) rotate(-2deg);
  }
  14% {
    transform: translate(-0, 0) rotate(6.5deg);
  }
  16% {
    transform: translate(0, 0) rotate(-1.5deg);
  }
  18% {
    transform: translate(0, 0) rotate(-2.5deg);
  }
  20% {
    transform: translate(-0, 0) rotate(2deg);
  }
  22% {
    transform: translate(0, 0) rotate(-2.5deg);
  }
  24% {
    transform: translate(0, 0) rotate(2.5deg);
  }
  26% {
    transform: translate(-0, 0) rotate(-2deg);
  }
  28% {
    transform: translate(0, 0) rotate(2deg);
  }
  30% {
    transform: translate(-0, 0) rotate(6.5deg);
  }
  32% {
    transform: translate(0, 0) rotate(-1.5deg);
  }
  34% {
    transform: translate(-0, 0) rotate(-1.5deg);
  }
  36% {
    transform: translate(0, 0) rotate(-2deg);
  }
  38% {
    transform: translate(0, 0) rotate(-1.5deg);
  }
  40% {
    transform: translate(0, 0) rotate(-2.5deg);
  }
  42% {
    transform: translate(-0, 0) rotate(4.5deg);
  }
  44% {
    transform: translate(-0, 0) rotate(-2deg);
  }
  46% {
    transform: translate(-0, 0) rotate(6.5deg);
  }
  48% {
    transform: translate(0, 0) rotate(2.5deg);
  }
  50% {
    transform: translate(0, 0) rotate(4.5deg);
  }
  52% {
    transform: translate(-0, 0) rotate(-2.5deg);
  }
  54% {
    transform: translate(0, 0) rotate(2deg);
  }
  56% {
    transform: translate(0, 0) rotate(-2.5deg);
  }
  58% {
    transform: translate(0, 0) rotate(-1.5deg);
  }
  60% {
    transform: translate(0, 0) rotate(2.5deg);
  }
  62% {
    transform: translate(-0, 0) rotate(-2.5deg);
  }
  64% {
    transform: translate(-0, 0) rotate(-1.5deg);
  }
  66% {
    transform: translate(-0, 0) rotate(2deg);
  }
  68% {
    transform: translate(0, 0) rotate(2deg);
  }
  70% {
    transform: translate(0, 0) rotate(-2.5deg);
  }
  72% {
    transform: translate(-0, 0) rotate(2deg);
  }
  74% {
    transform: translate(0, 0) rotate(6.5deg);
  }
  76% {
    transform: translate(0, 0) rotate(-2.5deg);
  }
  78% {
    transform: translate(-0, 0) rotate(-2deg);
  }
  80% {
    transform: translate(0, 0) rotate(-2.5deg);
  }
  82% {
    transform: translate(0, 0) rotate(2.5deg);
  }
  84% {
    transform: translate(0, 0) rotate(6.5deg);
  }
  86% {
    transform: translate(0, 0) rotate(-2.5deg);
  }
  88% {
    transform: translate(0, 0) rotate(4.5deg);
  }
  90% {
    transform: translate(-0, 0) rotate(4.5deg);
  }
  92% {
    transform: translate(-0, 0) rotate(4.5deg);
  }
  94% {
    transform: translate(-0, 0) rotate(-2deg);
  }
  96% {
    transform: translate(-0, 0) rotate(2.5deg);
  }
  98% {
    transform: translate(0, 0) rotate(-1.5deg);
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }
`

export const Beer = styled.img`
  cursor: pointer;

  transform: scale(0.8);
  opacity: 0;
  transition: opacity 100ms ease-in-out, transform 150ms ease-in-out;

  &.active {
    transform: scale(1);
    opacity: 1;
  }

  &:hover {
    animation-name: ${shake};
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`
