import React from "react"
import { useMediaQuery } from "react-responsive"
import Link from "gatsby-link"
import { AnimatePresence } from "framer-motion"
import { Container, Inner, NavItem, NavItemText } from "./main-nav.styles"

export default ({ active }) => {
  const navigation = [
    { key: "nav-01", name: "about", to: "/about" },
    { key: "nav-02", name: "portfolio", to: "/portfolio" },
    { key: "nav-03", name: "clients", to: "/clients" },
    { key: "nav-04", name: "contact", to: "/contact" },
  ]

  const isMobile = useMediaQuery({ maxWidth: "40em" })

  const listAnimation = {
    visible: {
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.5,
      },
    },
    hidden: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  }

  const itemAnimation = {
    visible: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  }

  return (
    <AnimatePresence>
      {active && (
        <Container
          key="main-nav"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={listAnimation}
        >
          <Inner>
            {navigation.map((item) => {
              return isMobile ? (
                <NavItem key={item.key} variants={itemAnimation}>
                  <NavItemText as={Link} to={item.to} activeClassName="active">
                    {item.name}
                  </NavItemText>
                </NavItem>
              ) : (
                <NavItem key={item.key} variants={itemAnimation}>
                  <NavItemText to={item.to} activeClassName="active">
                    {item.name}
                  </NavItemText>
                </NavItem>
              )
            })}
          </Inner>
        </Container>
      )}
    </AnimatePresence>
  )
}
