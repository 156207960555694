import styled from "styled-components"
import Text from "./text"

const Label = styled(Text)`
  display: block;
  text-transform: uppercase;
`

Label.defaultProps = {
  as: "small",
  fontFamily: "sansSerif",
  fontSize: ["mobile.-1", "desktop.-1"],
  letterSpacing: "0.125rem",
  color: "black.0",
}

export default Label
