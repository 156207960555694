import styled from "styled-components"
import Text from "./text"

const H0 = styled(Text)``

H0.defaultProps = {
  as: "h1",
  fontFamily: "serif",
  fontSize: ["mobile.6", "desktop.6"],
  fontWeight: 3,
  lineHeight: ["mobile.3.0", "desktop.3.0"],
}

export default H0
