import styled from "styled-components"
import { layout, space } from "styled-system"

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${space}
`
Container.defaultProps = {
  px: ["1.1rem"],
  py: ["1.8rem"],
  ml: "-1.1rem",
}

export const Inner = styled.div`
  position: relative;
  width: 2.6rem;
  height: 1.2rem;
  ${layout}
`
Inner.defaultProps = {}

export const Bar = styled.div`
  width: 100%;
  height: 0.2rem;
  background: #fff;
  transform: rotate(0);
  transition: ${(props) =>
    props.active
      ? "top 100ms ease-in, transform 300ms ease 100ms"
      : "transform 300ms ease, top 100ms ease-in 300ms"};

  &:nth-child(1) {
    position: absolute;
    top: ${(props) => (props.active ? `50%` : 0)};
    left: 0;
    transform: rotate(${(props) => (props.active ? `-45deg` : 0)});
  }

  &:nth-child(2) {
    position: absolute;
    top: ${(props) => (props.active ? `50%` : `100%`)};
    left: 0;
    transform: rotate(${(props) => (props.active ? `45deg` : 0)});
  }
`
