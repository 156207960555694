import styled from "styled-components"
import Text from "./text"

const Small = styled(Text)`
  display: inline-block;
`

Small.defaultProps = {
  as: "small",
  fontFamily: "sansSerif",
  fontSize: ["mobile.-1", "desktop.-1"],
  lineHeight: ["mobile.1.0", "desktop.1.0"],
}

export default Small
