import styled, { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import { motion } from "framer-motion"

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  html, body {
    color: #000;
    font-size: 10px;
    font-family: "Simplon Norm", sans-serif;
    background: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .tl-wrapper {
    float: initial;
  }
  .tl-edges {
    overflow-x: initial !important;
  }
  
  a {
    text-decoration: none;
  }

  ::selection {
    color: #fff;
    background: #000;
  }
`

export const Container = styled.div``

export const Main = styled(motion.div)``

export const Plane = styled(motion.div)({
  position: "fixed",
  backgroundColor: "#000",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  transform: "translateY(-100%)",
  zIndex: 1000,
})
