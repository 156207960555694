import { useLayoutEffect, useRef } from "react"
import { gsap, Power4 } from "gsap"

// export const useAnimation = animation => {
//   const tl = useRef()
//   const ref = useRef()

//   const setRef = useCallback(node => {
//     if (ref.current) tl.current?.kill()
//     if (node) tl.current = animation(node)
//     ref.current = node
//   })

//   return [setRef, tl]
// }

export const useAnimation = (targets, animation) => {
  const tl = useRef()

  useLayoutEffect(() => {
    gsap.config({ force3D: true })
    if (targets) tl.current = animation(targets)
    return () => tl.current?.kill()
  }, [])

  return tl
}

export const fadeIn = ({ plane, nav }) => {
  const tl = gsap.timeline({ paused: true })

  tl.to(plane.current, {
    y: 0,
    duration: 0.35,
    ease: Power4.easeOut,
  })

  tl.to(
    nav.current,
    {
      autoAlpha: 1,
      duration: 0.25,
      ease: Power4.easeOut,
    },
    "-=0.05"
  )

  return tl
}

export const fadeOut = ({ plane, nav }) => {
  const tl = gsap.timeline({ paused: true })

  tl.to(nav.current, {
    autoAlpha: 0,
    duration: 0.25,
    ease: Power4.easeIn,
  })

  tl.to(
    plane.current,
    {
      y: "-100%",
      duration: 0.35,
      ease: Power4.easeIn,
    },
    "-=0.05"
  )

  tl.set(plane.current, {
    y: "-100%",
  })

  return tl
}
